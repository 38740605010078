/* eslint-disable max-classes-per-file */
import fetchBuilder from 'fetch-retry';
import queryString from 'query-string';
import APImInterface from './interface';

const fetchRetry = fetchBuilder(fetch);

class APImError extends Error {
  constructor(response, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(`${response.url} returned ${response.status} ${response.statusText}`, ...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APImError);
    }

    this.name = 'APImError';
    // Custom debugging information
    this.response = response;
  }
}

function handleFetchErrors(response) {
  if (!response.ok) {
    const error = new APImError(response);
    return Promise.reject(error);
  }
  return Promise.resolve(response);
}

const aif = APImInterface;

class APImFetch {
  constructor() {
    this.baseUrl = process.env.VUE_APP_API_URL || '/';
    let params = {};
    if (process.env.VUE_APP_API_PARAMS) {
      params = JSON.parse(process.env.VUE_APP_API_PARAMS);
    }
    this.defaultParams = params;
    this.params = {};
    this.getToken = null;
    this.projectId = null;
    this.pending = {};
  }

  setParams(params) {
    // console.log('APImFetch.setParams', params);
    this.params = params;
  }

  cancelPending(id) {
    this.projectId = id;
    Object.values(this.pending).forEach((abort) => abort());
  }

  async fetch(
    fetchOptions,
    projectId,
    postProcess = (response) => response.json(),
    accept = 'application/json',
  ) {
    const projId = projectId || this.projectId;
    const controller = new AbortController();
    const options = {
      signal: controller.signal,
      method: fetchOptions.method || 'GET',
      headers: {
        Accept: accept,
      },
      retryOn: [500, 503],
      retries: 3,
      retryDelay: 1000,
      credentials: process.env.VUE_APP_CREDENTIALS || 'same-origin',
    };
    // const accessToken = sessionStorage.getItem('access_token');
    const accessToken = this.getToken ? await this.getToken() : null;
    if (accessToken) {
      options.headers.Authorization = `Bearer ${accessToken}`;
    }
    console.debug('fetch', fetchOptions.path, projId);
    if (projId) {
      options.headers['Apimetrics-Project-Id'] = projId;
      if (projId !== this.projectId) {
        options.headers['Apimetrics-No-Session'] = '1';
      }
    }
    if ('data' in fetchOptions) {
      options.body = JSON.stringify(fetchOptions.data);
    }
    if ('body' in fetchOptions) {
      options.body = fetchOptions.body;
    }
    if ('contentType' in fetchOptions) {
      options.headers['Content-Type'] = fetchOptions.contentType;
    }
    const parameters = {
      ...this.defaultParams,
      ...this.params,
      ...(fetchOptions.parameters || {}),
    };
    const paramStr = queryString.stringify(parameters);
    const url = `${this.baseUrl}${fetchOptions.path}${paramStr ? `?${paramStr}` : ''}`;
    //  console.debug(`fetch ${options.method} ${url}`);

    const pendingKey = `${options.method}:${url}`;
    const future = fetchRetry(url, options)
      .then((resp) => {
        delete this.pending[pendingKey];
        return resp;
      })
      .then(handleFetchErrors)
      .then(postProcess);
    if (!fetchOptions.noAbort) {
      this.pending[pendingKey] = () => controller.abort();
    }
    return future;
  }

  get(path, parameters, projectId, noAbort = false) {
    return this.fetch(
      {
        method: 'GET',
        path,
        parameters,
        noAbort,
      },
      projectId,
    );
  }

  getRaw(path, parameters, projectId, noAbort = false) {
    return this.fetch(
      {
        method: 'GET',
        path,
        parameters,
        noAbort,
      },
      projectId,
      (resp) => resp.text(),
    );
  }

  post(path, data, parameters, projectId, noAbort = false) {
    return this.fetch(
      {
        method: 'POST',
        path,
        parameters,
        data,
        noAbort,
        contentType: 'application/json',
      },
      projectId,
    );
  }

  put(path, data, parameters, projectId, noAbort = false) {
    return this.fetch(
      {
        method: 'PUT',
        path,
        parameters,
        data,
        noAbort,
        contentType: 'application/json',
      },
      projectId,
    );
  }

  delete(path, parameters, projectId, noAbort = false) {
    return this.fetch(
      {
        method: 'DELETE',
        path,
        parameters,
        noAbort,
      },
      projectId,
      (response) => (response.status === 204 ? null : response.json()),
    );
  }

  getAll(path, pageCb, parameters, projectId, cursorIn, limitIn, resultsIn, noAbort = false) {
    const cursor = cursorIn || null;
    const limit = limitIn || 100;
    const results = resultsIn || [];
    const params = { cursor, limit, ...parameters };

    return this.get(path, params, projectId, noAbort).then((data) => {
      const { meta } = data;
      if (pageCb) {
        pageCb(data);
      }
      if (meta.more && meta.next_cursor) {
        return this.getAll(path, pageCb, parameters, projectId, meta.next_cursor, limit, [
          ...results,
          ...data.results,
        ]);
      }

      return {
        meta,
        results: [...results, ...data.results],
      };
    });
  }

  exportProjectBlob(accept, parameters, projectId, noAbort = false) {
    return this.fetch(
      {
        method: 'GET',
        path: 'api/2/export/',
        parameters,
        noAbort,
      },
      projectId,
      (response) => response.blob(),
      accept,
    );
  }

  importProjectBlob(contentType, body, parameters, projectId, noAbort = false) {
    return this.fetch(
      {
        method: 'POST',
        path: 'api/2/import/',
        parameters,
        noAbort,
        contentType,
        body,
      },
      projectId,
      (response) => response.blob(),
    );
  }

  // LISTs of objects
  [aif.QUERY.AUTHS]({ pageCb }) {
    const url = 'api/2/auth/';
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.CALLS]({ pageCb }) {
    const url = 'api/2/calls/';
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.CALLS_BY_ORG]({ organization, pageCb }) {
    const url = `api/2/calls/organization/${organization || 'public'}/`;
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.DOWNTIMES]({ scheduleId, pageCb }) {
    const url = 'api/2/schedules/__KEY__/downtime/'.split('__KEY__').join(scheduleId);
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.ENVIRONMENTS]({ pageCb }) {
    const url = 'api/2/environment/';
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.FILES]({ pageCb }) {
    const url = 'api/2/files/';
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.REPORTS]({ pageCb }) {
    const url = 'api/2/reports/';
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.SCHEDULES]({ pageCb }) {
    const url = 'api/2/schedules/';
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.SCHEDULES_BY_CALL]({ callId, pageCb }) {
    const url = 'api/2/schedules/call/__KEY__/'.split('__KEY__').join(callId);
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.SCHEDULES_BY_WORKFLOW]({ workflowId, pageCb }) {
    const url = 'api/2/schedules/workflow/__KEY__/'.split('__KEY__').join(workflowId);
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.TOKENS]({ pageCb }) {
    const url = 'api/2/tokens/';
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.TOKENS_BY_AUTH]({ authId, pageCb }) {
    const url = 'api/2/tokens/auth/__KEY__/'.split('__KEY__').join(authId);
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.USERS_BY_ORG]({ organization, pageCb }) {
    const url = `api/2/users/organization/${organization || 'public'}/`;
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.WORKFLOWS]({ pageCb }) {
    const url = 'api/2/workflows/';
    return this.getAll(url, pageCb);
  }

  [aif.QUERY.WORKFLOWS_BY_CALL]({ callId, pageCb }) {
    const url = 'api/2/workflows/call/__KEY__/'.split('__KEY__').join(callId);
    return this.getAll(url, pageCb);
  }

  // Get Object by ID

  [aif.READ.CALL]({ id }) {
    const url = 'api/2/calls/__KEY__/'.split('__KEY__').join(id);
    return this.get(url);
  }

  [aif.READ.FILE]({ id }) {
    const url = 'api/2/files/__KEY__/'.split('__KEY__').join(id);
    return this.get(url);
  }

  [aif.READ.REPORT]({ id }) {
    const url = 'api/2/reports/__KEY__/'.split('__KEY__').join(id);
    return this.get(url);
  }

  [aif.READ.SCHEDULE]({ id }) {
    const url = 'api/2/schedules/__KEY__/'.split('__KEY__').join(id);
    return this.get(url);
  }

  [aif.READ.WORKFLOW]({ id }) {
    const url = 'api/2/workflows/__KEY__/'.split('__KEY__').join(id);
    return this.get(url);
  }

  // [aif.READ.STATS]({
  //   callId, locationId, kind, time,
  // }) {
  //   const url = 'api/2/calls/__KEY__/stats/passfail'.split('__KEY__').join(callId);
  //   const params = {
  //     location_id: locationId,
  //     kind,
  //     time: time ? `${time.format('YYYY-MM-DDTHH:mm:ss')}Z` : '',
  //   };
  //   return this.get(url, params);
  // }

  // [aif.READ.PUBLIC_STATS]({
  //   callId, locationId, reportId, minutes,
  // }) {
  //   const url = 'api/2/reports/__REPORT__/public/__KEY__/stats'
  //     .split('__KEY__')
  //     .join(callId)
  //     .split('__REPORT__')
  //     .join(reportId);
  //   const params = {
  //     location_id: locationId,
  //     minutes,
  //   };
  //   return this.get(url, params);
  // }

  // [aif.READ.STATS_METRIC]({
  //   callId, locationId, quantile, kind, metric, time,
  // }) {
  //   const url = 'api/2/calls/__KEY__/stats/metrics/__LOC__/'
  //     .split('__KEY__')
  //     .join(callId)
  //     .split('__LOC__')
  //     .join(locationId);
  //   const params = {
  //     quantile,
  //     dest_ip: '0',
  //     kind,
  //     category: 'PASS',
  //     time: time ? `${time.format('YYYY-MM-DDTHH:mm:ss')}Z` : '',
  //     metric,
  //   };
  //   return this.get(url, params);
  // }

  [aif.READ.SUBSCRIPTIONS]() {
    const url = 'api/2/subscriptions/';
    return this.get(url);
  }

  // CREATION
  [aif.CREATE.AUTH]({ data }) {
    const url = 'api/2/auth/';
    return this.post(url, data);
  }

  [aif.CREATE.CALL]({ data }) {
    const url = 'api/2/calls/';
    return this.post(url, data);
  }

  [aif.CREATE.TOKEN]({ data }) {
    const url = 'api/2/tokens/';
    return this.post(url, data);
  }

  [aif.CREATE.REPORT]({ data }) {
    const url = 'api/2/reports/';
    return this.post(url, data);
  }

  [aif.CREATE.SCHEDULE]({ data }) {
    const url = 'api/2/schedules/';
    return this.post(url, data);
  }

  [aif.CREATE.DOWNTIME]({ scheduleId, data }) {
    const url = 'api/2/schedules/__KEY__/downtime/'.split('__KEY__').join(scheduleId);
    return this.post(url, data);
  }

  [aif.CREATE.WORKFLOW]({ data }) {
    const url = 'api/2/workflows/';
    return this.post(url, data);
  }

  // UPDATE
  [aif.UPDATE.AUTH]({ id, data }) {
    const url = 'api/2/auth/__KEY__/'.split('__KEY__').join(id);
    return this.post(url, data);
  }

  [aif.UPDATE.CALL]({ id, data }) {
    const url = 'api/2/calls/__KEY__/'.split('__KEY__').join(id);
    return this.post(url, data);
  }

  [aif.UPDATE.DOWNTIME]({ id, data }) {
    const url = 'api/2/schedules/downtime/__KEY__/'.split('__KEY__').join(id);
    return this.post(url, data);
  }

  [aif.UPDATE.FILE]({ id, data }) {
    const url = 'api/2/files/__KEY__/'.split('__KEY__').join(id);
    return this.post(url, data);
  }

  [aif.UPDATE.REPORT]({ id, data }) {
    const url = 'api/2/reports/__KEY__/'.split('__KEY__').join(id);
    return this.post(url, data);
  }

  [aif.UPDATE.SCHEDULE]({ id, data }) {
    const url = 'api/2/schedules/__KEY__/'.split('__KEY__').join(id);
    return this.post(url, data);
  }

  [aif.UPDATE.WORKFLOW]({ id, data }) {
    const url = 'api/2/workflows/__KEY__/'.split('__KEY__').join(id);
    return this.post(url, data);
  }

  // DELETE

  [aif.DELETE.CALL]({ id }) {
    const url = 'api/2/calls/__KEY__/'.split('__KEY__').join(id);
    return this.delete(url);
  }

  [aif.DELETE.SCHEDULE]({ id }) {
    const url = 'api/2/schedules/__KEY__/'.split('__KEY__').join(id);
    return this.delete(url);
  }

  [aif.DELETE.DOWNTIME]({ id }) {
    const url = 'api/2/schedules/downtime/__KEY__/'.split('__KEY__').join(id);
    return this.delete(url);
  }

  [aif.DELETE.FILE]({ id }) {
    const url = 'api/2/files/__KEY__/'.split('__KEY__').join(id);
    return this.delete(url);
  }

  [aif.DELETE.REPORT]({ id }) {
    const url = 'api/2/reports/__KEY__/'.split('__KEY__').join(id);
    return this.delete(url);
  }

  [aif.DELETE.WORKFLOW]({ id }) {
    const url = 'api/2/workflows/__KEY__/'.split('__KEY__').join(id);
    return this.delete(url);
  }

  // Per API call
  getInsightsScore({ callId, weekly }) {
    const url = 'api/2/insights/call/__KEY__/'.split('__KEY__').join(callId);
    const params = {
      weekly,
    };
    return this.get(url, params);
  }

  getPublicInsightsScore({ callId, reportId, weekly }) {
    const url = 'api/2/reports/__REPORT_ID__/public/__KEY__/insights'
      .split('__KEY__')
      .join(callId)
      .split('__REPORT_ID__')
      .join(reportId);
    const params = {
      weekly,
    };
    return this.get(url, params);
  }

  getNotificationsByCall({ callId, since, pageCb }) {
    const url = 'api/2/notifications/call/__KEY__/'.split('__KEY__').join(callId);
    const params = { since: `${since.format('YYYY-MM-DDTHH:mm:ss')}Z` };
    return this.getAll(url, pageCb, params);
  }

  getStatsStatusRange({ callId, locationId, kind, start, end }) {
    const url = 'api/2/calls/__KEY__/stats/passfail_range'.split('__KEY__').join(callId);
    const params = {
      location_id: locationId,
      kind,
      start: `${start.format('YYYY-MM-DDTHH:mm:ss')}Z`,
      end: `${end.format('YYYY-MM-DDTHH:mm:ss')}Z`,
    };
    return this.get(url, params);
  }

  getStatsMetricRange({ callId, locationId, quantile, metric, start, end }) {
    const url = 'api/2/calls/__KEY__/stats/metrics/__LOC__/'
      .split('__KEY__')
      .join(callId)
      .split('__LOC__')
      .join(locationId);
    const params = {
      quantile,
      dest_ip: '0',
      category: 'PASS',
      start: `${start.format('YYYY-MM-DDTHH:mm:ss')}Z`,
      end: `${end.format('YYYY-MM-DDTHH:mm:ss')}Z`,
    };
    if (Array.isArray(metric)) {
      params['metric[]'] = metric;
    } else {
      params.metric = metric;
    }
    return this.get(url, params);
  }

  // Actions & un-cached API calls
  // Interface to make API calls directly, not for vuex store use

  getEnivironmentVariable({ workspace, variableName }) {
    const url = `api/2/environment/${workspace}/${variableName}`;
    return this.get(url);
  }

  setEnivironmentVariable({ workspace, variableName, value }) {
    const url = `api/2/environment/${workspace}/${variableName}`;
    return this.post(url, { value });
  }

  addCallToSchedule({ id, callId }) {
    const url = 'api/2/schedules/__KEY__/call/__CALL_ID__'
      .split('__KEY__')
      .join(id)
      .split('__CALL_ID__')
      .join(callId);
    return this.post(url, null).then(() => this[aif.READ.SCHEDULE]({ id }));
  }

  addWorkflowToSchedule({ id, workflowId }) {
    const url = 'api/2/schedules/__KEY__/run/__WORK_ID__'
      .split('__KEY__')
      .join(id)
      .split('__WORK_ID__')
      .join(workflowId);

    return this.put(url, null);
  }

  removeCallFromSchedule({ id, callId }) {
    const url = 'api/2/schedules/__KEY__/call/__CALL_ID__'
      .split('__KEY__')
      .join(id)
      .split('__CALL_ID__')
      .join(callId);
    return this.delete(url).then(() => this[aif.READ.SCHEDULE]({ id }));
  }

  removeWorkflowFromSchedule({ id, workflowId }) {
    const url = 'api/2/schedules/__KEY__/run/__WORK_ID__'
      .split('__KEY__')
      .join(id)
      .split('__WORK_ID__')
      .join(workflowId);
    return this.delete(url);
  }

  triggerCallRun({ callId, locationId, runDelay = 0, context = undefined }) {
    const data = { location_id: locationId, run_delay: runDelay, context };
    const url = 'api/2/calls/__KEY__/run'.split('__KEY__').join(callId);
    return this.post(url, data);
  }

  triggerWorkflowRun({ workflowId, locationId, runDelay = 0, context = undefined }) {
    const data = { location_id: locationId, run_delay: runDelay, context };
    const url = 'api/2/workflows/__KEY__/run'.split('__KEY__').join(workflowId);
    return this.post(url, data);
  }

  getInsightsRank({ weekly = true, projectId }) {
    const url = 'api/2/insights/rank/';
    const params = { weekly };
    return this.get(url, params, projectId);
  }

  getAudits({ cursor = null, limit = null }) {
    const url = 'api/2/audits/';
    const params = { cursor, limit };
    return this.get(url, params);
  }

  getAuditsForOrg({ orgId, cursor = null, limit = null }) {
    const url = `api/2/audits/organization/${orgId}/`;
    const params = { cursor, limit };
    return this.get(url, params);
  }

  getAuditsForResource({ id, cursor = null, limit = null }) {
    const url = `api/2/audits/resource/${id}/`;
    const params = { cursor, limit };
    return this.get(url, params);
  }

  getInsightsReport({ id }) {
    const url = `api/2/insights/${id}/`;
    const params = {};
    return this.get(url, params);
  }

  getInsightsOutliers({ id }) {
    const url = `api/2/insights/${id}/outliers`;
    const params = {};
    return this.get(url, params);
  }

  getNotifications({ cursor = null, limit = null }) {
    const url = 'api/2/notifications/';
    const params = { cursor, limit };
    return this.get(url, params);
  }

  getStatsForCall({ id, params }) {
    const url = `api/2/calls/${id}/stats/before`;
    return this.get(url, params);
  }

  getStatusStatsForCall({ id, params }) {
    const url = `api/2/calls/${id}/stats/passfail_range`;
    return this.get(url, params);
  }

  getStatsQuantiles({ id, locationId, start, end, metric, quantile, destIp }) {
    const url = `api/2/calls/${id}/stats/metrics/${locationId}/`;
    const params = {
      start,
      end,
      metric,
      quantile,
      dest_ip: destIp,
    };
    return this.get(url, params);
  }

  getResult({ id, projectId }) {
    const url = `api/2/results/${id}/`;
    const params = { mode: 'full' };
    return this.get(url, params, projectId);
  }

  getResultContent({ id, projectId }) {
    const url = `api/2/results/${id}/content`;
    const params = {};
    return this.getRaw(url, params, projectId);
  }

  getResults({ callId = null, before, resultCategory, limit, cursor, projectId }) {
    const url = callId ? `api/2/results/call/${callId}/` : 'api/2/results/';
    const params = { limit, cursor };
    if (before) {
      params.time = before;
    }
    if (resultCategory) {
      params.result_category = resultCategory;
    }
    return this.get(url, params, projectId);
  }

  getWorkflowResults({ workflowId = null, before, resultCategory, limit, cursor, projectId }) {
    // No workflowId is not implemented yet
    const url = workflowId ? `api/2/workflows/${workflowId}/results/` : 'api/2/workflows/results/';
    const params = { limit, cursor };
    if (before) {
      params.time = before;
    }
    // Not implemented yet
    if (resultCategory) {
      params.result_category = resultCategory;
    }
    return this.get(url, params, projectId);
  }

  getWorkflowResult({ id, projectId }) {
    const url = `api/2/workflows/results/${id}/`;
    const params = {};
    return this.get(url, params, projectId);
  }

  getCallVarsUsed({ id, projectId }) {
    const url = `api/2/calls/${id}/variables/used`;
    return this.get(url, {}, projectId);
  }

  getCallVarsSet({ id, projectId }) {
    const url = `api/2/calls/${id}/variables/set`;
    return this.get(url, {}, projectId);
  }

  getCallConditions({ id, projectId }) {
    const url = `api/2/calls/${id}/conditions/`;
    return this.get(url, {}, projectId);
  }

  setCallConditions({ id, conditions, meta, projectId }) {
    const url = `api/2/calls/${id}/conditions/`;
    return this.post(url, { conditions, meta }, projectId);
  }

  getProjectConditions({ projectId }) {
    const url = 'api/2/conditions/';
    return this.get(url, {}, projectId);
  }

  setProjectConditions({ conditions, meta, projectId }) {
    const url = 'api/2/conditions/';
    return this.post(url, { conditions, meta }, projectId);
  }

  getCallWebhooks({ callId, pageCb, projectId }) {
    const url = `api/2/calls/${callId}/webhooks/`;
    return this.getAll(url, pageCb, {}, projectId);
  }

  createCallWebhook({ callId, data, projectId }) {
    const url = `api/2/calls/${callId}/webhooks/`;
    return this.put(url, data, projectId);
  }

  saveCallWebhookById({ callId, id, data, projectId }) {
    const url = `api/2/calls/${callId}/webhooks/${id}`;
    return this.post(url, data, projectId);
  }

  deleteCallWebhookById({ callId, id, projectId }) {
    const url = `api/2/calls/${callId}/webhooks/${id}`;
    return this.delete(url, {}, projectId);
  }

  getWebhooks({ pageCb, projectId }) {
    const url = 'api/2/webhooks/';
    return this.getAll(url, pageCb, {}, projectId);
  }

  createWebhook({ data, projectId }) {
    const url = 'api/2/webhooks/';
    return this.put(url, data, projectId);
  }

  saveWebhookById({ id, data, projectId }) {
    const url = `api/2/webhooks/${id}`;
    return this.post(url, data, projectId);
  }

  deleteWebhookById({ id, projectId }) {
    const url = `api/2/webhooks/${id}`;
    return this.delete(url, {}, projectId);
  }

  // Importer API
  // verifyApimaticKey(key) {
  //   this.foo = this.foo;
  //   return fetch(`http://127.0.0.1:5000/verify?apimatic_key=${key}`, { method: 'GET', headers: { Accept: 'application/json' } })
  //     .then(handleFetchErrors)
  //     .then(response => response.json());
  // }

  getPostmanWorkspaces() {
    const url = 'api/2/import/workspaces/';
    return this.get(url);
  }

  getPostmanWorkspace({ workspaceId }) {
    const url = `api/2/import/workspaces/${workspaceId}/`;
    return this.get(url);
  }

  getPostmanCollections() {
    const url = 'api/2/import/collections/';
    return this.get(url);
  }

  getPostmanEnvironments() {
    const url = 'api/2/import/environments/';
    return this.get(url);
  }

  verifyUrl({ specUrl }) {
    const params = { url: specUrl };
    const url = 'api/2/import/verify/';
    return this.get(url, params);
  }

  verifyApimaticKey({ apimaticKey }) {
    const url = 'api/2/import/verify/';
    const params = { apimatic_key: apimaticKey };
    return this.get(url, params);
  }

  verifyPostmanCollection({ postmanCollectionUid }) {
    const url = 'api/2/import/verify/';
    const params = { postman_collection_uid: postmanCollectionUid };
    return this.get(url, params);
  }

  matchUrl({ specUrl }) {
    const params = { url: specUrl };
    const url = 'api/2/import/match/';
    return this.get(url, params);
  }

  matchApimaticKey({ apimaticKey }) {
    const url = 'api/2/import/match/';
    const params = { apimatic_key: apimaticKey };
    return this.get(url, params);
  }

  matchPostmanCollection({ postmanCollectionUid }) {
    const url = 'api/2/import/match/';
    const params = { postman_collection_uid: postmanCollectionUid };
    return this.get(url, params);
  }

  syncUrl({ specUrl, matchInfo }) {
    const params = { url: specUrl, match: matchInfo };
    const url = 'api/2/import/sync/';
    return this.post(url, params);
  }

  syncApimaticKey(apimaticKey, matchInfo) {
    const params = { apimatic_key: apimaticKey, match: matchInfo };
    const url = 'api/2/import/sync/';
    return this.post(url, params);
  }

  syncPostmanCollection({ postmanCollectionUid, matchInfo }) {
    const params = { postman_collection_uid: postmanCollectionUid, match: matchInfo };
    const url = 'api/2/import/sync/';
    return this.post(url, params);
  }

  postFileUrl(data) {
    const url = 'api/2/files/url';
    return this.post(url, data);
  }

  // User profile

  async getProjects() {
    const url = 'api/2/account/projects';
    const resp = await this.get(url, null, null, true);

    // Some old test accounts have "role" access to a project
    // that is no longer in an org, just remove them for now
    resp.projects = resp.projects.reduce((arrIn, projInfo) => {
      const out = arrIn;
      if (projInfo.role_id && !projInfo.project.org_id) {
        console.log('No org but using role', projInfo);
        return out;
      }
      out.push(projInfo);
      return out;
    }, []);
    return resp;
  }

  async getBilling() {
    const url = 'api/2/account/billing';
    return this.get(url, null, null, true);
  }

  async getProjSubscription({ id }) {
    const url = `api/2/account/subscription/proj/${id}`;
    return this.get(url, null, null, true);
  }

  async getProjSubscriptionUsage({ id }) {
    const url = `api/2/account/subscription/proj/${id}/usage`;
    return this.get(url, null, null, true);
  }

  async updateProjSubscription({ id, data }) {
    const url = `api/2/account/subscription/proj/${id}`;
    return this.post(url, data, null, null, true);
  }

  async getOrgSubscription({ id }) {
    const url = `api/2/account/subscription/org/${id}`;
    return this.get(url, null, null, true);
  }

  async getOrgSubscriptionUsage({ id }) {
    const url = `api/2/account/subscription/org/${id}/usage`;
    return this.get(url, null, null, true);
  }

  async updateOrgSubscription({ id, data }) {
    const url = `api/2/account/subscription/org/${id}`;
    return this.post(url, data, null, null, true);
  }

  async getProjInvoices({ id }) {
    const url = `api/2/account/invoices/proj/${id}`;
    return this.get(url, null, null, true);
  }

  async getOrgInvoices({ id }) {
    const url = `api/2/account/invoices/org/${id}`;
    return this.get(url, null, null, true);
  }

  getProjectStatsStatusRange({ locationId, kind, start, end, pageCb }) {
    const url = 'api/2/project/stats/passfail_range';
    const params = {
      location_id: locationId,
      kind,
      start: `${start.format('YYYY-MM-DDTHH:mm:ss')}Z`,
      end: `${end.format('YYYY-MM-DDTHH:mm:ss')}Z`,
    };
    return this.getAll(url, pageCb, params);
  }

  getProjectSynopsis({ time, kind, projectId }) {
    const url = 'api/2/project/synopsis';
    const params = {};
    if (time) {
      params.time = time;
    }
    if (kind) {
      params.kind = kind;
    }
    return this.get(url, params, projectId);
  }

  getOrgSynopsis({ orgId, time, kind }) {
    const url = `api/2/project/organization/${orgId}/`;
    const params = {};
    if (time) {
      params.time = time;
    }
    if (kind) {
      params.kind = kind;
    }
    return this.get(url, params);
  }

  getProjectSLOs({ projectId }) {
    const url = 'api/2/project/slo/';
    const params = {};
    return this.get(url, params, projectId);
  }

  setProjectSLOs({
    objectives = [],
    thresholds = [],
    includeTags = [],
    excludeTags = [],
    projectId,
  }) {
    const url = 'api/2/project/slo/';
    const data = {
      objectives,
      thresholds,
      include_tags: includeTags,
      exclude_tags: excludeTags,
    };
    const params = {};
    return this.post(url, data, params, projectId);
  }

  deleteProjectSLOs({ projectId }) {
    const url = 'api/2/project/slo/';
    const params = {};
    return this.delete(url, params, projectId);
  }

  addRole(roleId) {
    const url = `api/2/account/role/${roleId}/`;
    return this.put(url);
  }

  removeRole(roleId) {
    const url = `api/2/account/role/${roleId}/`;
    return this.delete(url);
  }

  createProject(data) {
    const url = 'api/2/account/projects';
    return this.post(url, data);
  }

  getQuota() {
    const url = 'api/2/account/quota?clear_cache=1';
    return this.get(url);
  }

  updateAccount({ data }) {
    const url = 'api/2/account/';
    return this.post(url, data);
  }

  verifyEmail() {
    const url = 'api/2/account/verify';
    return this.post(url, {});
  }

  resetPassword({ email }) {
    const url = 'api/2/account/reset_password';
    const data = email ? { email } : {};
    return this.post(url, data);
  }

  acceptInvite({ id }) {
    console.assert(id, 'No id in acceptInvite');
    const url = `api/2/account/invite/${id}`;
    return this.post(url, {});
  }

  rejectInvite({ id }) {
    console.assert(id, 'No id in rejectInvite');
    const url = `api/2/account/invite/${id}`;
    return this.delete(url, {});
  }

  verifyInviteSecret({ id, secret }) {
    console.assert(id, 'No id in verifyInviteSecret');
    console.assert(secret, 'No secret in verifyInviteSecret');
    const url = `api/2/account/invite/${id}/${secret}/`;
    return this.get(url);
  }

  acceptInviteSecret({ id, secret }) {
    console.assert(id, 'No id in acceptInviteSecret');
    console.assert(secret, 'No secret in acceptInviteSecret');
    const url = `api/2/account/invite/${id}/${secret}/`;
    return this.post(url, {});
  }

  tagProject() {
    const url = 'api/2/export/tag';
    return this.post(url, {});
  }

  tagProjectForOpenAPI({ fileId, force = false }) {
    const url = 'api/2/openapi/tag';
    return this.post(url, null, { file_id: fileId, force });
  }

  // Org Admin calls

  getOrganization({ id }) {
    console.assert(id, 'No id in getOrganization');
    const url = `api/2/organizations/${id}/`;
    return this.get(url, { include_quota: true }, null, true);
  }

  updateOrganization({ id, data }) {
    console.assert(id, 'No id in updateOrganization');
    console.assert(data, 'No data in updateOrganization');
    const url = `api/2/organizations/${id}/`;
    return this.post(url, data, null, null, true);
  }

  updateProject({ id, data }) {
    console.assert(id, 'No id in updateProject');
    console.assert(data, 'No data in updateProject');
    const url = `api/2/projects/${id}/`;
    return this.post(url, data);
  }

  updateConformanceSettings({ data }) {
    console.assert(data, 'No data in updateConformanceSettings');
    const url = `api/3/governance/conformance/settings`;
    return this.put(url, data);
  }

  getConformanceSettings() {
    const url = `api/3/governance/conformance/settings`;
    return this.get(url);
  }

  getAccountsInOrg({ orgId, pageCb }) {
    console.assert(orgId, 'No orgId in getAccountsInOrg');
    const url = `api/2/organizations/${orgId}/accounts/`;
    return this.getAll(url, pageCb);
  }

  getAccountInOrgById({ orgId, id }) {
    console.assert(orgId, 'No orgId in getAccountInOrgById');
    console.assert(id, 'No accountId in getAccountInOrgById');
    const url = `api/2/organizations/${orgId}/accounts/${id}/`;
    return this.get(url);
  }

  deleteAccountInOrgById({ orgId, id }) {
    console.assert(orgId, 'No orgId in deleteAccountInOrgById');
    console.assert(id, 'No id in deleteAccountInOrgById');
    const url = `api/2/organizations/${orgId}/accounts/${id}/`;
    return this.delete(url);
  }

  addRoleToAccountInOrgById({ orgId, id, roleId }) {
    console.assert(orgId, 'No orgId in addRoleToAccountInOrgById');
    console.assert(id, 'No id in addRoleToAccountInOrgById');
    console.assert(roleId, 'No roleId in addRoleToAccountInOrgById');
    const url = `api/2/organizations/${orgId}/accounts/${id}/role/${roleId}/`;
    return this.post(url, {});
  }

  deleteRoleFromAccountInOrgById({ orgId, id, roleId }) {
    console.assert(orgId, 'No orgId in deleteRoleFromAccountInOrgById');
    console.assert(id, 'No id in deleteRoleFromAccountInOrgById');
    console.assert(roleId, 'No roleId in deleteRoleFromAccountInOrgById');
    const url = `api/2/organizations/${orgId}/accounts/${id}/role/${roleId}/`;
    return this.delete(url);
  }

  getInvitesInOrg({ orgId, pageCb }) {
    console.assert(orgId, 'No orgId in getInvitesInOrg');
    const url = `api/2/organizations/${orgId}/invites/`;
    return this.getAll(url, pageCb);
  }

  createInviteInOrg({ orgId, data }) {
    console.assert(orgId, 'No orgId in createInviteInOrg');
    console.assert(data, 'No data in createInviteInOrg');
    const url = `api/2/organizations/${orgId}/invites/`;
    return this.post(url, data);
  }

  deleteInviteInOrgById({ orgId, id }) {
    console.assert(orgId, 'No orgId in deleteInviteInOrgById');
    console.assert(id, 'No accountId in deleteInviteInOrgById');
    const url = `api/2/organizations/${orgId}/invites/${id}/`;
    return this.delete(url);
  }

  getInvitesForProj({ projectId, pageCb }) {
    console.assert(projectId, 'No projectId in getInvitesForProj');
    const url = `api/2/projects/${projectId}/invites/`;
    return this.getAll(url, pageCb);
  }

  createInviteForProj({ projectId, data }) {
    console.assert(projectId, 'No projectId in createInviteForProj');
    console.assert(data, 'No data in createInviteForProj');
    const url = `api/2/projects/${projectId}/invites/`;
    return this.post(url, data);
  }

  deleteInviteForProjById({ projectId, id }) {
    console.assert(projectId, 'No projectId in deleteInviteForProjById');
    console.assert(id, 'No accountId in deleteInviteForProjById');
    const url = `api/2/projects/${projectId}/invites/${id}/`;
    return this.delete(url);
  }

  getProjectsInOrg({ orgId, tag, systemTag, includeQuota, pageCb }) {
    console.assert(orgId, 'No orgId in getProjectsInOrg');
    const url = `api/2/organizations/${orgId}/projects/`;
    const parameters = { system_tag: systemTag, tag, include_quota: includeQuota };
    return this.getAll(url, pageCb, parameters);
  }

  getAccessForOrg({ orgId, pageCb }) {
    console.assert(orgId, 'No orgId in getAccessForOrg');
    const url = `api/2/organizations/${orgId}/projects/access`;
    return this.getAll(url, pageCb, {});
  }

  getRolesForOrg({ orgId, pageCb }) {
    console.assert(orgId, 'No orgId in getAccessForOrg');
    const url = `api/2/organizations/${orgId}/roles/access`;
    return this.getAll(url, pageCb, {});
  }

  createProjectInOrg({ orgId, data }) {
    console.assert(orgId, 'No orgId in createProjectInOrg');
    console.assert(data, 'No data in createProjectInOrg');
    const url = `api/2/organizations/${orgId}/projects/`;
    return this.post(url, data);
  }

  getProjectInOrgById({ orgId, id }) {
    console.assert(orgId, 'No orgId in getProjectInOrgById');
    console.assert(id, 'No id in getProjectInOrgById');
    const url = `api/2/organizations/${orgId}/projects/${id}/`;
    return this.get(url, id);
  }

  updateProjectInOrgById({ orgId, id, data }) {
    console.assert(orgId, 'No orgId in updateProjectInOrgById');
    console.assert(id, 'No id in updateProjectInOrgById');
    console.assert(data, 'No data in updateProjectInOrgById');
    const url = `api/2/organizations/${orgId}/projects/${id}/`;
    return this.post(url, data);
  }

  deleteProjectInOrgById({ orgId, id }) {
    console.assert(orgId, 'No orgId in deleteProjectInOrgById');
    console.assert(id, 'No id in deleteProjectInOrgById');
    const url = `api/2/organizations/${orgId}/projects/${id}/`;
    return this.delete(url);
  }

  getRolesForProjectById({ id, pageCb }) {
    console.assert(id, 'No id in getRolesForProjectById');
    const url = `api/2/projects/${id}/roles/`;
    return this.getAll(url, pageCb);
  }

  getRolesInOrg({ orgId, pageCb }) {
    console.assert(orgId, 'No orgId in getRolesInOrg');
    const url = `api/2/organizations/${orgId}/roles/`;
    return this.getAll(url, pageCb);
  }

  createRoleInOrg({ orgId, data }) {
    console.assert(orgId, 'No orgId in createRoleInOrg');
    console.assert(data, 'No data in createRoleInOrg');
    const url = `api/2/organizations/${orgId}/roles/`;
    return this.post(url, data);
  }

  updateRoleInOrgById({ orgId, id, data }) {
    console.assert(orgId, 'No orgId in updateRoleInOrgById');
    console.assert(data, 'No data in updateRoleInOrgById');
    const url = `api/2/organizations/${orgId}/roles/${id}/`;
    return this.post(url, data);
  }

  deleteRoleInOrgById({ orgId, id }) {
    console.assert(orgId, 'No orgId in deleteRoleInOrgById');
    console.assert(id, 'No id in deleteRoleInOrgById');
    const url = `api/2/organizations/${orgId}/roles/${id}/`;
    return this.delete(url);
  }

  // Project admins only
  createRoleForProjectById({ id, data }) {
    console.assert(id, 'No id in createRoleForProjectById');
    console.assert(data, 'No data in createRoleForProjectById');
    const url = `api/2/projects/${id}/roles/`;
    return this.post(url, data);
  }

  deleteRoleForProjectById({ projectId, id }) {
    console.assert(projectId, 'No projectId in deleteRoleForProjectById');
    console.assert(id, 'No id in deleteRoleForProjectById');
    const url = `api/2/projects/${projectId}/roles/${id}/`;
    return this.delete(url);
  }

  getAccessForProjectById({ id, pageCb }) {
    console.assert(id, 'No id in getAccessForProjectById');
    const url = `api/2/projects/${id}/access/`;
    return this.getAll(url, pageCb);
  }

  createAccessForProjectById({ id, data }) {
    console.assert(id, 'No id in createAccessForProjectById');
    console.assert(data, 'No data in createAccessForProjectById');
    const url = `api/2/projects/${id}/access/`;
    return this.post(url, data);
  }

  deleteAccessForProjectById({ projectId, id }) {
    console.assert(projectId, 'No projectId in deleteAccessForProjectById');
    console.assert(id, 'No id in deleteAccessForProjectById');
    const url = `api/2/projects/${projectId}/access/${id}/`;
    return this.delete(url);
  }

  getApiKeys({ pageCb }) {
    const url = 'api/2/api_keys/';
    return this.getAll(url, pageCb);
  }

  createApiKey({ data }) {
    console.assert(data, 'No data in createApiKey');
    const url = 'api/2/api_keys/';
    return this.post(url, data);
  }

  deleteApiKeyById({ id }) {
    console.assert(id, 'No id in deleteApiKeyById');
    const url = `api/2/api_keys/${id}/`;
    return this.delete(url);
  }

  moveResource({ id, copy, type, destination, dryRun }) {
    console.assert(id, 'No id in moveResource');
    console.assert(copy !== undefined, 'No copy in moveResource');
    console.assert(type, 'No type in moveResource');
    console.assert(destination, 'No destination in moveResource');
    console.assert(dryRun !== undefined, 'No dryRun in moveResource');
    const url = `api/2/manage/${copy ? 'copy' : 'move'}`;
    return this.post(url, {
      id,
      type,
      destination,
      dry_run: dryRun,
    });
  }

  buildCallFromCurl({ request }) {
    const url = 'api/2/calls/build/curl';
    return this.post(url, { request });
  }

  getSubscriptions() {
    const url = 'api/2/subscriptions/';
    return this.get(url);
  }

  subscribeEmail(groupId) {
    const url = `api/2/subscriptions/${groupId}/`;
    return this.put(url);
  }

  unsubscribeEmail(groupId) {
    const url = `api/2/subscriptions/${groupId}/`;
    return this.delete(url);
  }

  getProjectSubscriptions(projectId = null) {
    const url = 'api/2/project-subscriptions/projects';
    return this.getAll(url, null, null, projectId, null, null, null, true);
  }

  sendProjectEmail(projectId = null) {
    const url = 'api/2/project-subscriptions/trigger';
    return this.post(url, {}, {}, projectId, true);
  }

  setProjectSubscriptions(daily, weekly, monthly, projectId = null) {
    const options = [];
    if (daily) {
      options.push('daily');
    }
    if (weekly) {
      options.push('weekly');
    }
    if (monthly) {
      options.push('monthly');
    }
    const url = 'api/2/project-subscriptions/';
    return this.post(url, { options }, {}, projectId, true);
  }

  removeProjectSubscriptions(projectId = null) {
    const url = 'api/2/project-subscriptions/';
    return this.delete(url, {}, projectId, true);
  }

  apiExpertSubscribe(colId) {
    const url = `api/2/api_expert/subscription/${colId}/`;
    return this.post(url, {}, {}, true);
  }

  apiExpertCheckSubs() {
    const url = 'api/2/api_expert/subscription/';
    return this.get(url, null, null, true);
  }

  getUndeliverable(emails, projectId = null) {
    const url = 'api/2/subscriptions/undeliverable';
    return this.post(url, { emails }, {}, projectId, true);
  }

  // Site Admins only
  adminGetOrgs({ systemTag = 'active', tag, limit = 100, incQuota = false, pageCb }) {
    const url = 'api/2/organizations/';
    return this.getAll(
      url,
      pageCb,
      { system_tag: systemTag, tag, include_quota: incQuota },
      null,
      null,
      limit,
      null,
      true,
    );
  }

  adminGetOrg({ id }) {
    const url = `api/2/organizations/${id}/`;
    return this.get(url, null, null, true);
  }

  adminGetAccounts({ query }) {
    const url = 'api/2/account/admin/';
    return this.get(url, { query }, null, true);
  }

  adminGetAccount({ accountId }) {
    const url = `api/2/account/admin/${accountId}/`;
    return this.get(url, null, null, true);
  }

  adminGetProjectAccounts({ projectId }) {
    const url = `api/2/account/admin/project/${projectId}/`;
    return this.get(url, null, null, true);
  }

  adminGetProject({ id }) {
    const url = `api/2/users/${id}/`;
    return this.get(url, null, id, true);
  }

  getAgentInfo() {
    const url = 'api/2/agents/info';
    return this.get(url, null, null, true);
  }

  getIncident({ incidentId }) {
    const url = `api/2/incidents/${incidentId}`;
    return this.get(url);
  }

  logout() {
    const url = 'api/2/logout';
    return this.get(url, null, null, true);
  }

  conformanceResults({ resolved, cursor, limit }) {
    const url = `api/3/governance/conformance/results`;
    return this.get(url, { cursor, limit, resolved }, null);
  }

  conformanceResolutionUpdate({ conformance_result_id, result_id, status, notes }) {
    const url = `api/3/governance/conformance/resolution`;
    return this.fetch({
      method: 'POST',
      path: url,
      data: { conformance_result_id, result_id, status, notes },
      contentType: 'application/json',
    });
  }

  conformanceSummaryResults() {
    const url = `api/3/governance/conformance/results/summary`;
    return this.get(url);
  }

  conformanceResultsEvaluate() {
    const url = `api/3/governance/conformance/results/evaluate`;
    return this.post(url, null, null, null, true);
  }

  governanceChanges({ from, to }) {
    const url = `api/3/governance/changes`;
    const from_timestamp = from.toISOString();
    const to_timestamp = to.toISOString();
    return this.get(url, { from_timestamp, to_timestamp }, null, true);
  }

  getSuppliers() {
    const url = 'api/3/suppliers';
    return this.get(url, null, null, true);
  }

  getProjectSuppliers() {
    const url = 'api/3/project/suppliers';
    return this.get(url);
  }

  setProjectSuppliers(data) {
    const url = 'api/3/project/suppliers';
    return this.fetch({
      method: 'PUT',
      path: url,
      data,
      contentType: 'application/json',
    });
  }

  getSupplierResults() {
    const url = 'api/3/project/suppliers/results';
    return this.get(url);
  }

  getInventoryResults({ from, to, cursor }) {
    const url = 'api/3/inventory';
    return this.get(url, { from_timestamp: from, to_timestamp: to, cursor, limit: 50 });
  }

  getOasCoverage({ from, to }) {
    const url = 'api/3/inventory/coverage';
    return this.get(url, { from_timestamp: from, to_timestamp: to });
  }

  getOasEndpoints({ oas_id }) {
    const url = `api/3/inventory/endpoints/${oas_id}`;
    return this.get(url);
  }

  uploadOas(file) {
    const url = 'api/3/files/oas';
    const body = new FormData();
    body.append('file', file, file.name);
    return this.fetch({
      method: 'POST',
      path: url,
      body,
    });
  }

  uploadOasUrl(url) {
    const data = { url };
    const endpoint = 'api/3/files/oas_url';
    return this.post(endpoint, data);
  }

  getEndpointClassifications(spec) {
    const url = `api/3/governance/conformance/${spec}/endpoints`;
    return this.get(url);
  }

  classificationChange({ data, spec }) {
    const url = `api/3/governance/conformance/${spec}/endpoints`;
    const body = JSON.stringify(data);
    return this.fetch({
      method: 'POST',
      path: url,
      body,
      contentType: 'application/json',
    });
  }

  organizationCertificates({ orgId }) {
    const url = `api/3/organizations/${orgId}/certificates/`;
    return this.get(url);
  }

  createOrganizationCertificate({ orgId, data }) {
    const url = `api/3/organizations/${orgId}/certificates/`;
    return this.fetch({
      method: 'POST',
      path: url,
      data,
      contentType: 'application/json',
    });
  }

  updateOrganizationCertificate({ orgId, id, data }) {
    const url = `api/3/organizations/${orgId}/certificates/${id}`;
    return this.fetch(
      {
        method: 'PUT',
        path: url,
        data,
        contentType: 'application/json',
      },
      undefined,
      () => null,
    );
  }

  deleteOrganizationCertificate({ orgId, id }) {
    const url = `api/3/organizations/${orgId}/certificates/${id}`;
    return this.delete(url);
  }

  jiraChatLogin() {
    const path = 'api/2/chat-login';
    return this.fetch(
      {
        method: 'GET',
        path,
        noAbort: true,
      },
      null,
      (resp) => resp.text(),
      'application/jwt',
    );
  }

  projectAvailableSecurityProfiles({ projectId }) {
    return this.get(`api/3/projects/${projectId}/security-profiles`);
  }

  getDowntimes({ orgId }) {
    const url = `api/3/organizations/${orgId}/downtimes`;
    return this.get(url);
  }

  createOrgDowntime({ orgId, data }) {
    const url = `api/3/organizations/${orgId}/downtimes`;
    return this.post(url, data);
  }

  updateDowntime({ orgId, id, data }) {
    const url = `api/3/organizations/${orgId}/downtimes/${id}`;
    return this.put(url, data);
  }

  deleteDowntime({ orgId, id }) {
    const url = `api/3/organizations/${orgId}/downtimes/${id}`;
    return this.delete(url);
  }

  importSampleProjects({ project_ids }) {
    const url = `api/2/import/sample`;
    return this.post(url, { project_ids });
  }
}

export { APImFetch, APImError, APImInterface };
